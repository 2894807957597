@use "../config/" as *;
@forward "../swiper/swiper-bundle";
@forward '../modules/static';
// html {
.load-bg-pattern {
    .waf-row-video-detail {
        &::before {
            opacity: 0.1;
            content: "";
            aspect-ratio: 1/1;
            width: 20%;
            background-size: cover;
            filter: brightness(0) invert(1);
            @include position(null, null, -10%, -10%);
            @include bgImg('svg/hexagon.svg');
        }
    }
}
.waf-component {
    &.waf-detail {
        @extend %pt-0;
        @extend %pb-4;
        @extend %px-1;
    }
    &.waf-news-details {
        padding-inline: var(--space-0);
    }
}
.waf-detail {
    .head-wrap {
        @extend %py-2;
        @extend %flex-sb-c;
    }
    .article-meta {
        .meta {
            @extend %white-5;
            @extend %font-12-pr;
            &.meta-count {
                @extend %accent;
            }
        }
        .meta-count {
            font-weight: 700;
            @extend %ml-1;
            @extend %gap-1;
            @extend %pl-2;
            @extend %relative;
            &::after {
                content: "";
                width: 0.1rem;
                @extend %h-100;
                @extend %white-bg-5;
                @extend %position-t-l;
            }
            &::before {
                content: "\e82a";
                font-family: $font-icon;
                font-size: 1.6rem;
                font-weight: 400;
                @extend %mr-1;
            }
        }
    }
    .social-share {
        width: 3rem;
        height: 3rem;
        @extend %relative;
        .share {
            &-icon {
                width: 3rem;
                height: 3rem;
            }
            &-label {
                @extend %d-none;
            }
        }
        .icon-b-share {
            @extend %white-5;
            &::before {
                @include icon(share, 14);
            }
        }
    }
    .social {
        &-wrap {
            @extend %d-none;
        }
        &-icon {
            width: 3rem;
            height: 3rem;
            border: .1rem solid var(--white);
            @extend %font-14;
            @extend %flex-c-c;
            @extend %white;
            @extend %circle-radius;
            &:not(:last-child) {
                margin-right: var(--space-2);
            }
        }
        &-items {
            @extend %flex;
        }
    }
    .embed-responsive {
        @extend %mb-2;
    }
    video {
        @extend %w-100;
    }
    iframe {
        @extend %w-100;
    }
    .item-type-icon {
        @extend %d-none;
    }
    .detail-section {
        @extend %flex;
        @extend %w-100;
        @extend %mb-2;
    }
    .btn-action {
        width: 10rem;
        height: 3.5rem;
        @extend %mx-auto;
        @extend %font-14-pm;
        @extend %secondary-bg;
        @extend %full-radius;
        @extend %flex-c-c;
        @extend %white;
    }
    .article-featured-media {
        @extend %mb-2;
    }
    .table-responsive {
        @extend %pb-2;
    }
    .detail-title {
        @extend %font-18-pb;
        @extend %secondary-dark;
        @extend %my-0;
        @extend %pb-2;
    }
    .detail-section {
        .container-wrap {
            @extend %mb-4;
        }
        img,
        video {
            @extend %half-radius;
        }
    }
    .article {
        &-tags {
            @extend %d-none;
        }
    }
    &.waf-gallery-page {
        padding: 0;
        .article-body {
            @extend %px-3;
        }
    }
}
.waf-news-details {
    .article {
        &-description {
            bottom: -10.5rem;
            @extend %px-3;
            @extend %flex-column-fe-n;
            @extend %position-b-l;
            p {
                font-style: italic;
                @include truncate(6, 16);
                height: unset;
                @extend %p-0;
            }
        }
        &-body {
            padding-top: 10.5rem;
        }
    }
}
.waf-row-video-detail {
    @extend %relative;
    @extend %secondary-bg;
}
.waf-row-news-detail {
    @extend %white-bg;
    .article-featured-media {
        @extend %mx-3-neg;
        .img-box {
            border-radius: 0;
        }
        & + div {
            margin-top: -8rem;
            position: relative;
            padding: var(--space-2);
            @include backdropBlur(1rem);
            @extend %medium-grey-bg-8;
            @extend %half-radius;
            @extend %mb-6;
            [data-component="SI-image"]:first-child {
                @extend %d-none;
            }
        }
    }
    .article-body {
        @extend %mt-4;
        @extend %px-3;
    }
    .detail-container {
        @extend %my-3;
    }
}
.waf-video-page {
    .content-section {
        @extend %px-3;
    }
    .meta-wrap {
        @extend %flex-sb-c;
    }
    .article-detail {
        row-gap: var(--space-8);
        @extend %flex-column;
    }
    .video-section {
        @extend %relative;
        &::after {
            content: '';
            height: .1rem;
            @extend %w-100;
            @extend %white-bg-2;
            @include position(null, null, var(--space-4-neg), 0);
        }
    }
    .related-video-section {
        @extend %pb-3;
        @extend %px-3;
        .head {
            @extend %flex-sb-c;
            @extend %pb-0;
            @extend %mb-4;
        }
        .title {
            @extend %font-16-pb;
            @extend %uppercase;
            @extend %pb-0;
        }
        .auto-play-section {
            column-gap: var(--space-2);
            @extend %flex-sb-c;
            .label {
                @extend %white;
                @extend %pb-0;
                @extend %font-10-pm;
            }
        }
        .reaction-section {
            @extend %d-none;
        }
        .article {
            &-thumbnail {
                border-radius: 0;
                .img-box {
                    overflow: visible;
                }
            }
            &-item {
                overflow: hidden;
                @extend %half-radius;
                @extend %secondary-light-bg-2;
            }
            &-list {
                row-gap: var(--space-3);
                @extend %flex-column-n-n;
                @include listing-card(rhs);
            }
            &-title {
                @extend %secondary-light;
                @include truncate(2, 12);
            }
            &-meta {
                .meta {
                    @extend %font-10-pr;
                    @extend %secondary-light;
                }
            }
            &-content {
                @extend %flex;
                @extend %flex-wrap;
            }
        }
        .toggle {
            @extend %relative;
            &-checkbox {
                @extend %d-none;
                &:checked + .toggle-btn:before {
                    @extend %primary-bg;
                }
                &:checked + .toggle-btn:after {
                    left: 2.1rem;
                }
            }
            &-btn {
                width: 3.5rem;
                height: 1.5rem;
                @extend %d-block;
                @extend %transition;
                &::before,
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    cursor: pointer;
                }
                &::before {
                    transition: border-color 0.25s ease;
                    @extend %h-100;
                    @extend %w-100;
                    @extend %full-radius;
                    @extend %accent-bg;
                }
                &::after {
                    width: 1.3rem;
                    height: 1.3rem;
                    transition: left 0.25s ease;
                    left: .1rem;
                    position: absolute;
                    top: .1rem;
                    @extend %circle-radius;
                    @extend %white-bg;
                }
            }
        }
        .icon-time-wrapper {
            order: -1;
            line-height: 1;
            .timestamp {
                @extend %d-none;
            }
            .item-type-icon {
                @extend %flex;
                @extend %secondary-light;
            }
        }
    }
    .swiper-button-prev,
    .swiper-button-next {
        @extend %d-none;
    }
    .body {
        @extend %y-auto;
        @include custom-scroll();
    }
    a {
        text-decoration: unset;
    }
}
@include mq(col-md) {
    .waf-detail {
        .social-share {
            width: unset;
            height: unset;
            .share-icon {
                display: none;
            }
            .icon-b-share {
                &::before {
                    content: unset;
                }
            }
        }
        .social-wrap {
            display: flex;
        }
        &.waf-gallery-page {
            padding: 0;
        }
        &.waf-gallery-page,
        &.waf-news-details {
            .article-head,
            .article-body {
                padding-inline: 14%;
            }
        }
        &.waf-video-page {
            padding-block: var(--space-6);
        }
    }
    .waf-news-details {
        .article {
            &-body {
                padding-inline: 15%;
                margin-inline: var(--space-3-neg);
                padding-top: 5rem;
            }
            &-description {
                padding-inline: 14%;
                bottom: -6rem;
                p {
                    @include truncate(2, 16);
                    height: unset;
                }
            }
        }
    }
    .waf-row-video-detail {
        > .waf-view {
            max-width: var(--container-max-width);
            margin-inline: auto;
        }
        .col-lg-12 {
            padding-inline: 0;
        }
        .waf-detail {
            padding-inline: 0;
        }
    }
    .waf-component {
        &.waf-detail {
            max-width: var(--container-max-width);
            margin-inline: auto;
        }
    }
    .waf-row-news-detail {
        .article-featured-media {
            margin-inline: 0;
            .img-box {
                border-radius: var(--half-radius);
            }
            & + div {
                margin-block: var(--space-3);
                padding-block: var(--space-2);
                padding-inline: 0;
                background-color: transparent;
                @include backdropBlur(0);
            }
        }
        .article {
            &-body {
                padding-inline: 15%;
                margin-inline: 0;
            }
        }
    }
    .waf-video-page {
        .head-wrap {
            padding: 0;
        }
        .title {
            font-size: 2rem;
        }
        .article-detail {
            column-gap: var(--space-3);
            row-gap: 0;
            @include flex-config(null, row, space-between);
        }
        .video-section {
            width: 70%;
            flex-basis: 70%;
            &::after {
                content: unset;
            }
        }
        .related-video-section {
            width: 30%;
            flex-basis: 30%;
        }
    }
}