@use "../config" as *;
.waf-row-list-banner {
    margin-top: calc(var(--container-max-width) * .43 + var(--space-6));
    @extend %relative;
}
.waf-listing {
    @extend %common-card-with-more-btn;
    &.waf-related-list {
        @extend %pt-3;
        .first-list{
            @extend %mx-2-neg;
        }
        &.waf-related-highlight-list {
            .article {
                &-list {
                    .waf-child-wrapper {
                        width: var(--container-max-width);
                        flex-basis: var(--container-max-width);
                        flex-shrink: unset;
                    }
                }
            }
        }
        .waf-child-wrapper {
            @include position(null, null, calc(100% + 1.5rem), null);
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-row-list-banner {
        margin-top: unset;
    }
    .waf-listing {
        @include commonCardWithMoreButton();
        .item-type-video {
            .item-type-icon {
                &::before {
                    font-size: 2.5rem;
                }
            }
        }
        &.waf-related-list {
            padding-block: var(--space-4) var(--space-3);
            .first-list{
                padding: 0;
                margin: 0;
            }
            &.waf-related-highlight-list {
                @include commonCardWithMoreButton(overlay, 2);
                .article {
                    &-title {
                        @include truncate(2, 18, 24, $font-primary);
                    }
                }
            }
            .waf-child-wrapper {
                margin-block: var(--space-3) 0;
                position: unset;
            }
        }
    }
}
@media screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
    .waf-row-list-banner {
        margin-top: 1rem;
    }
    .waf-listing {
        &.waf-related-list {
            .article {
                &-list {
                    @include card-count(3, var(--space-3));
                }
            }
        }
    }
}