@use "../config/" as *;
.waf-gallery-page {
    .article {
        &-title {
            @extend %font-16;
            @extend %mb-0;
            @extend %text-center;
            @extend %white;
        }
        &-description {
            @extend %white;
            p {
                @extend %white;
            }
        }
        &-body {
            @extend %mt-3;
        }
        &-gallery {
            &-list {
                @include card-count(2, 1rem, wrap);
            }
            &-item {
                @extend %half-radius;
                @extend %relative;
                
                &:nth-child(3n - 2) {
                    @extend %w-100;
                }
            }
        }
        &-content {
            @extend %d-none;
        }
        &-wrap {
            @extend %h-100;
        }
        &-thumbnail {
            @extend %h-100;
            img {
                position: static;
            }
        }
    }
    .btn-more {
        @extend %position-t-l;
        @extend %w-100;
        @extend %h-100;
    }
    .swiper {
        @extend %h-100;
        &-pagination-fraction {
            position: static;
            width: auto;
            line-height: 3.2rem;
            @extend %text-left;
            @extend %font-14;
            @extend %white-5;
            @extend %flex-sa-fe;
        }
        &-scrollbar {
            @extend %d-none;
        }
        &-pagination-current {
            font-weight: 700;
            @extend %white;
            @extend %font-32;
        }
    }
    .modal {
        &-head {
            height: 29%;
            @extend %w-100;
            @extend %pt-12;
            @extend %pb-8;
            @extend %flex-c-fe;
            .btn-close {
                @include position(3rem, 3rem, null, null, fixed);
            }
        }
        &-body {
            height: 42%;
            @extend %p-0;
        }
        &-footer {
            height: 29%;
            @extend %w-100;
            @extend %px-3;
            @extend %pb-6;
            @include flex-config(flex, row-reverse, space-between, flex-end);
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-gallery-page {
        .article {
            &-body {
                margin-inline: var(--space-3-neg);
                margin-block: var(--space-6);
                padding-inline: 15%;
            }
            &-gallery {
                &-list {
                    @include card-count(4, 1.5rem, wrap);
                }
                &-item {
                    &:nth-child(3n - 2) {
                        width: calc(25% - 1.125rem);
                    }
                }
            }
            &-item {
                opacity: 0.4;
                transform: scale(0.9);
                &.swiper-slide-active {
                    opacity: 1;
                    transform: scale(1);
                }
            }
            &-thumbnail {
                .img-box {
                    background-color: transparent;
                    @include flex-config(flex, null, center, center);
                }
                img {
                    object-fit: cover;
                    width: auto;
                    height: 42rem;
                    border-radius: var(--half-radius);
                }
            }
            &-wrap {
                width: 100%;
            }
            &-title {
                font-size: 2rem;
            }
        }
        .modal {
            &-head {
                height: auto;
            }
            &-body {
                height: auto;
                width: 100%;
            }
            &-footer {
                height: auto;
                padding-top: var(--space-4);
                padding-inline: var(--container-white-space);
            }
        }
        .swiper {
            width: 100%;
            padding-inline: var(--container-white-space);
            &-button-prev, &-button-next {
                top: 54%;
                bottom: unset;
            }
            &-button-next {
                right: 19%;
            }
            &-button-prev {
                left: 19%;
            }
        }
    }
}